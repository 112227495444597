import React from 'react';

import 'driver.js/dist/driver.css';
import { useDisclosure } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { TProfile } from 'src/types/types';
import { useProfilesPageTour } from 'src/hooks';
import { useAuth } from 'src/components/AuthProvider/AuthProvider';
import useAppStore from 'src/store';

const defaultProfileValue = { id: '', name: '', content: '' };

export const useProfiles = () => {
  const { user } = useAuth();
  const profiles = useAppStore((store) => store.profiles);
  const createProfile = useAppStore((store) => store.createProfile);
  const deleteProfile = useAppStore((store) => store.deleteProfile);
  const updateProfile = useAppStore((store) => store.updateProfile);
  const [selectedProfile, setSelectedProfile] =
    React.useState<TProfile>(defaultProfileValue);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const { profilesPageTour } = useProfilesPageTour();

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setSelectedProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (selectedProfile.name && selectedProfile.content) {
      const { id, name, content } = selectedProfile;
      onClose();
      if (id) {
        await updateProfile(id, name, content);
      } else {
        await createProfile(name, content);
      }
    }
    setSelectedProfile(defaultProfileValue);
    onClose();
  };

  const handleDeleteProfile = async () => {
    onDeleteModalClose();
    onClose();
    await deleteProfile(selectedProfile.id);
    setSelectedProfile(defaultProfileValue);
  };

  const onCreateProfileClick = () => {
    setSelectedProfile(defaultProfileValue);
    onOpen();
  };

  const onDeleteProfileClick = (profileId: string) => {
    setSearchParams({ profileId });
    onDeleteModalOpen();
  };

  const onEditProfileClick = (profileId: string) => {
    const profile = profiles.find((profile) => profile.id === profileId);

    if (profile) {
      setSelectedProfile(profile);
      onOpen();
    }
  };

  const deleteModalCloseHandler = () => {
    setSearchParams({});
    onDeleteModalClose();
  };

  const deleteProfileHandler = async () => {
    const id = searchParams.get('profileId');
    if (id) {
      onDeleteModalClose();
      await deleteProfile(id);
    }
  };

  React.useEffect(() => {
    if (user && !user.isProfileTourPassed) {
      profilesPageTour.drive();
    }
  }, [profilesPageTour, user]);

  return {
    profiles,
    onCreateProfileClick,
    onDeleteProfileClick,
    onEditProfileClick,
    selectedProfile,
    handleChange,
    isOpen,
    onClose,
    handleDeleteProfile,
    handleSubmit,
    deleteModalCloseHandler,
    deleteProfileHandler,
    isDeleteModalOpen,
  };
};
