import React from 'react';

import { Table, FilterFn, flexRender } from '@tanstack/react-table';

import { RankingInfo } from '@tanstack/match-sorter-utils';

// import { TMockApplication, applicationsMocks } from './mock';
import {
  Box,
  Button,
  ButtonGroup,
  Table as ChakraTable,
  Flex,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import TableSearch from './TableSearch';
import ColumnFilter from './ColumnFilter';

// import { makeData, Person } from './makeData';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

type Props<T> = {
  table: Table<T>;
  searchValue?: string;
  onSearchHandler: (value: string | number) => void;
  onRowClick?: (id: string) => void;
};

const ReactTable = <T extends { id: string }>({
  table,
  searchValue,
  onSearchHandler,
  onRowClick,
}: Props<T>) => {
  return (
    <div>
      <div>
        <TableSearch
          value={searchValue ?? ''}
          onChange={onSearchHandler}
          placeholder="Search all columns..."
        />
      </div>
      <ChakraTable>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header, i, arr) => {
                return (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pl={i ? 6 : 0}
                    pr={i === arr.length - 1 ? 0 : 6}
                    verticalAlign="top"
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <Box
                          cursor={
                            header.column.getCanSort() ? 'pointer' : 'default'
                          }
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          mb="auto"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </Box>
                        {header.column.getCanFilter() ? (
                          <div>
                            <ColumnFilter
                              column={header.column}
                              table={table}
                              placeholder={
                                header.id === 'status' ? 'Filter...' : undefined
                              }
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Tr
                key={row.original.id}
                onClick={() => onRowClick && onRowClick(row.original.id)}
                cursor={onRowClick ? 'pointer' : 'default'}
                _hover={
                  onRowClick && {
                    bg: 'gray.50',
                  }
                }
              >
                {row.getVisibleCells().map((cell, i, cells) => {
                  return (
                    <Td
                      key={cell.id}
                      pl={i && i !== cells.length - 1 ? 6 : 0}
                      pr={i === cells.length - 1 ? 0 : 6}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>
      <Box h={0.5} />
      <Flex align="center" justify="space-between" mt={2}>
        <Text fontSize="sm">{table.options.data.length} elements</Text>
        <ButtonGroup variant="outline" size="sm">
          <Button
            rel="prev"
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            previous
          </Button>
          <Button
            rel="next"
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            next
          </Button>
        </ButtonGroup>
      </Flex>
    </div>
  );
};

export default ReactTable;
