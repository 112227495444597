import { gql } from '@apollo/client';

export const IMPROVE_RESUME = gql`
  mutation improveResume($id: ID!, $versionId: ID!, $content: String) {
    improveResume(data: { id: $id, versionId: $versionId, content: $content }) {
      id
      content
      language
      versions {
        id
        createdAt
        content
        tag
      }
    }
  }
`;
