import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import withAuth from './components/WithAuth/withAuth';
import Layout from './components/Layout/Layout';
import MyCVsPage from './pages/CVsPage/MyCvsPage';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ProfilesPage from './pages/ProfilesPage/ProfilesPage';
import RecommendationsPage from './pages/RecommendationsPage/RecommendationsPage';
import CoverLettersPage from './pages/CoverLettersPage/CoverLettersPage';
import CoverLetterPage from './pages/CoverLetterPage/CoverLetterPage';
import ApplicationsPage from './pages/ApplicationsPage/ApplicationsPage';
import RecommendationSuccessPage from './pages/RecommendationSuccessPage';

const AuthPage = React.lazy(() => import('./components/AuthPage/AuthPage'));
const LinkedInAuthPage = React.lazy(
  () => import('./components/AuthPage/LinkedInAuthPage'),
);
const ResumePage = React.lazy(() => import('./pages/ResumePage/ResumePage'));
const EditProfile = React.lazy(() => import('./pages/EditProfilePage'));
const BillingPage = React.lazy(() => import('./pages/BillingPage'));
const SuccessPage = React.lazy(() => import('./pages/SuccessPage'));
const ConfirmDataForm = React.lazy(
  () => import('./components/ConfirmDataForm/ConfirmDataForm'),
);
const PasswordRecovery = React.lazy(
  () => import('./components/AuthPage/PasswordRecovery'),
);
const TemplatesPage = React.lazy(() => import('./pages/TemplatesPage'));
const RecommendationLetterUserPage = React.lazy(
  () => import('./pages/RecommendationLetterPage/RecommendationLetterUserPage'),
);
const RecommenderPage = React.lazy(
  () => import('./pages/RecommenderPage/RecommenderPage'),
);

const App: React.FC = () => {
  return (
    <Routes>
      <Route path={'/auth'}>
        <Route
          path={'/auth'}
          element={
            <Suspense fallback={<Spinner />}>
              <AuthPage />
            </Suspense>
          }
        />
        <Route
          path={'/auth/linkedin'}
          element={
            <Suspense fallback={<Spinner />}>
              <LinkedInAuthPage />
            </Suspense>
          }
        />
        <Route
          path={'/auth/password_recovery'}
          element={
            <Suspense fallback={<Spinner />}>
              <PasswordRecovery />
            </Suspense>
          }
        />
      </Route>
      <Route path={'/recommendation-letter'} element={<RecommenderPage />} />
      <Route
        path={'/recommendation-letter-approved'}
        element={<RecommendationSuccessPage />}
      />
      <Route element={<Layout />} path={'/'}>
        <Route path={'/'} element={<MyCVsPage />} />
        <Route path={'/my-cvs'} element={<MyCVsPage />} />
        <Route path={'/recommendations'} element={<RecommendationsPage />} />
        <Route
          path={'/recommendations/:letterId'}
          element={
            <Suspense fallback={<Spinner />}>
              <RecommendationLetterUserPage />
            </Suspense>
          }
        />
        <Route path={'/cover-letters'} element={<CoverLettersPage />} />
        <Route
          path={'/cover-letters/:letterId'}
          element={
            <Suspense fallback={<Spinner />}>
              <CoverLetterPage />
            </Suspense>
          }
        />
        <Route path={'/applications'} element={<ApplicationsPage />} />
        <Route
          path={'/cv/:id'}
          element={
            <Suspense fallback={<Spinner />}>
              <ResumePage />
            </Suspense>
          }
        />
        <Route
          path={'/cv/:id/recommendation_letters/:letterId'}
          element={
            <Suspense fallback={<Spinner />}>
              <RecommendationLetterUserPage />
            </Suspense>
          }
        />
        <Route
          path={'/edit-profile'}
          element={
            <Suspense fallback={<Spinner />}>
              <EditProfile />
            </Suspense>
          }
        />
        <Route
          path={'/edit-profile/edit-password'}
          element={<ResetPassword />}
        />
        <Route
          path={'/profiles'}
          element={
            <Suspense fallback={<Spinner />}>
              <ProfilesPage />
            </Suspense>
          }
        />
        <Route
          path={'/success'}
          element={
            <Suspense fallback={<Spinner />}>
              <SuccessPage />
            </Suspense>
          }
        />
        <Route
          path={'/templates'}
          element={
            <Suspense fallback={<Spinner />}>
              <TemplatesPage />
            </Suspense>
          }
        />
        <Route
          path={'/billing'}
          element={
            <Suspense fallback={<Spinner />}>
              <BillingPage />
            </Suspense>
          }
        />
        <Route
          path={'/confirm-data'}
          element={
            <Suspense fallback={<Spinner />}>
              <ConfirmDataForm />
            </Suspense>
          }
        />
        <Route path={'*'} element={<div>404</div>} />
      </Route>
    </Routes>
  );
};

export default withAuth(App);
