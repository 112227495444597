import { ToastId, ToastProps, createStandaloneToast } from '@chakra-ui/react';
import { GraphQLError } from 'graphql';

const { toast } = createStandaloneToast();

export const getToast = (props: ToastProps, toastId?: ToastId) => {
  if (toastId) {
    toast.close(toastId);
  }
  return toast({
    status: 'success',
    duration: 3000,
    isClosable: true,
    position: 'bottom-right',
    ...props,
  });
};

export const getLoadingToast = (props: ToastProps) =>
  toast({
    status: 'loading',
    isClosable: true,
    position: 'bottom-right',
    ...props,
  });

export const onErrorToastHandler = (error: Error, toastId?: ToastId) => {
  if (toastId) {
    toast.close(toastId);
  }
  toast({
    title: 'Something gone wrong.',
    description: error.message,
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'bottom-right',
  });
};

export const onErrorsToastHandler = (
  errors: readonly GraphQLError[],
  toastId?: ToastId,
) => {
  if (toastId) {
    toast.close(toastId);
  }
  errors.forEach((error) => onErrorToastHandler(error));
};
