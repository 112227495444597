import { useNavigate } from 'react-router-dom';
import { FormikHelpers, FormikValues } from 'formik';
import useAppStore from 'src/store';
import { TCreateResumeInput } from 'src/store/slices';

export const useCreateResume = () => {
  // const [createResumeMutation, { loading, data }] = useMutation(CREATE_RESUME);
  const createResumeAction = useAppStore((store) => store.createResume);
  // const initResumes = useAppStore((store) => store.initResumes);
  const nav = useNavigate();

  const createResume = async (
    values?: FormikValues,
    formikActions?: FormikHelpers<FormikValues>,
  ) => {
    if (!!formikActions) {
      formikActions.setSubmitting(false);
      formikActions.resetForm();
    }
    const resume = await createResumeAction({
      ...values,
    } as TCreateResumeInput);
    if (resume) {
      nav(`/cv/${resume.id}`);
    }
  };

  return { createResume };
};
