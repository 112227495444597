import {
  useState,
  useEffect,
  ChangeEventHandler,
  ChangeEvent,
  useCallback,
  useRef,
} from 'react';

import { saveAs } from 'file-saver';
import { ApolloError, useMutation } from '@apollo/client';
import {
  UPDATE_RESUME_PUBLISHED,
  UPDATE_RESUME_PROFILE,
  TRANSFORM_TO_PDF,
  IMPROVE_RESUME,
  ATS_TEST,
  TRANSFORM_TO_HTML,
  DELETE_VERSION,
  UPDATE_RESUME_LANGUAGE,
  CREATE_RECOMMENDATION,
} from '../graphql/mutations';
import { DELETE_RESUME } from '../graphql/mutations/deleteResume';
import { useNavigate, useParams } from 'react-router-dom';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import {
  ECVLanguage,
  EVersionTag,
  IResume,
  TInitialCVData,
  TVersion,
} from 'src/types/types';
import { useAppToast } from './useAppToast';
import parseISO from 'date-fns/parseISO';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import useAppStore from 'src/store';

const defaultInitialData = {
  language: ECVLanguage.ENGLISH,
  title: '',
  company: '',
  industry: '',
  published: false,
  job: '',
  description: '',
  profileId: '',
};

export type RecommendationInput = {
  company: string;
  name: string;
  email: string;
  currentCompany: string;
  currentJobTitle: string;
  recommenderJobTitle: string;
  recommendeeJobTitle: string;
};

export const useCVActions = () => {
  const { t } = useTranslation('cv');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [initialData, setInitialData] =
    useState<TInitialCVData>(defaultInitialData);
  const resumes = useAppStore((store) => store.resumes);
  const setResumes = useAppStore((store) => store.setResumes);
  const profiles = useAppStore((store) => store.profiles);
  const updateResume = useAppStore((store) => store.updateResume);
  const recommendations = useAppStore((store) => store.recommendations);
  const setRecommendations = useAppStore((store) => store.setRecommendations);
  const { showToast, closeToast } = useAppToast('cv-page');
  const onError = async (error: ApolloError) => {
    await closeToast();
    showToast({ content: error.message, status: 'error' });
  };
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
  ) => {
    editorRef.current = editor;
  };
  const [setPublished] = useMutation(UPDATE_RESUME_PUBLISHED, {
    onCompleted: (data, clientOptions) => {
      const { published } = data.updateResume;
      if (typeof published === 'boolean') {
        if (published) {
          showToast({
            content: 'CV successfully published.',
            status: 'success',
          });
        } else {
          showToast({
            content: 'CV successfully unpublished.',
            status: 'success',
          });
        }
      }
      setResumes(
        resumes.map((r) =>
          r.id === clientOptions?.variables?.id ? { ...r, published } : r,
        ),
      );
    },
    onError,
  });
  const [setProfile] = useMutation(UPDATE_RESUME_PROFILE, {
    onCompleted: (data, clientOptions) => {
      const profile = profiles.find(
        (profile) => profile.id === clientOptions?.variables?.profileId,
      );
      setInitialData((prev) => ({
        ...prev,
        profileId: clientOptions?.variables?.profileId || prev.profileId,
      }));
      if (profile && profile.id === data?.updateResume?.profile?.id) {
        setResumes(
          resumes.map((r) =>
            r.id === clientOptions?.variables?.id ? { ...r, profile } : r,
          ),
        );
      }
    },
    onError,
  });
  const [deleteResumeMutation] = useMutation(DELETE_RESUME, {
    onCompleted: (data, clientOptions) => {
      if (data) {
        setResumes(
          resumes.filter((r) => r.id !== clientOptions?.variables?.id),
        );
      }
    },
    onError,
  });
  const [improveResume] = useMutation(IMPROVE_RESUME, {
    onCompleted: async (data) => {
      if (data.improveResume) {
        const latestVersion = getLatestVersion(data.improveResume?.versions);
        setCurrentVersion(latestVersion);
        setResumeVersions(data.improveResume?.versions);
        setResumes(
          resumes.map((r) =>
            r.id === data.improveResume.id
              ? { ...r, ...data.improveResume }
              : r,
          ),
        );
      }
    },
    onError,
  });
  const [error] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState<ECVLanguage>(
    ECVLanguage.ENGLISH,
  );
  const getLatestVersion = (versions: TVersion[]) =>
    versions.reduce((latest: TVersion, current: TVersion) => {
      const currentDate = parseISO(current.createdAt);
      const latestDate = parseISO(latest.createdAt);

      return currentDate > latestDate ? current : latest;
    }, versions[0]);
  const [transformToPdf] = useMutation(TRANSFORM_TO_PDF);
  const [currentVersion, setCurrentVersion] = useState<TVersion | null>(null);
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const [, setIsRewriting] = useState<boolean>(false);
  const [content, setContent] = useState<null | string>(null);
  const [preview, setPreview] = useState<undefined | string>(undefined);
  const [resumeVersions, setResumeVersions] = useState<TVersion[]>([]);
  const [deleteVersionMutation] = useMutation(DELETE_VERSION, {
    onCompleted: (data, clientOptions) => {
      if (data.removeVersion) {
        const removeVersionId = clientOptions?.variables?.id;
        setResumeVersions((versions) => {
          const filteredVersions = versions.filter(
            (version) => version.id !== removeVersionId,
          );
          setCurrentVersion(filteredVersions[0]);

          return filteredVersions;
        });
        setResumes(
          resumes.map((r) =>
            r.id === id
              ? {
                  ...r,
                  versions: r.versions.filter((v) => v.id !== removeVersionId),
                }
              : r,
          ),
        );
      }
    },
    onError,
  });
  const [createRecommendationMutation] = useMutation(CREATE_RECOMMENDATION, {
    onCompleted: (data) => {
      const newRecommendation = data.createRecommendation;
      if (newRecommendation) {
        setRecommendations([{ ...newRecommendation }, ...recommendations]);
        navigate(`recommendation_letters/${newRecommendation.id}`);
      }
    },
    onError,
  });
  const [isPublished, setResumePublished] = useState(initialData.published);

  const updateResumeHandler = useCallback(
    async (value: string, tag?: EVersionTag) => {
      if (value !== content) {
        setContent(value);
        const updatedResume = await updateResume({
          id: id ?? '',
          content: value,
          tag,
        });
        if (updatedResume) {
          const latestVersion = getLatestVersion(updatedResume?.versions);
          setCurrentVersion(latestVersion);
          setResumeVersions(updatedResume?.versions);
        }
      }
    },
    [content, id, updateResume],
  );

  const debouncedUpdate = useDebouncedCallback(async (value: string) => {
    await updateResumeHandler(value);
  }, 10000);

  const debouncedUpdateCVData = useDebouncedCallback(
    async (initialData: TInitialCVData) => {
      await updateResume({ id: id ?? '', ...initialData });
    },
    1500,
  );

  const handleUpdateCVData: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { name, value } = e.target;
    setInitialData((prev) => {
      const result = { ...prev, [name]: value };
      debouncedUpdateCVData(result);

      return result;
    });
  };

  const handleVersionSelect = (versionId: string) => {
    setIsPreviewing(false);
    const version = resumeVersions.find((version) => version.id === versionId);
    if (version) setCurrentVersion(version);
  };

  const handleDeleteResume = async () => {
    await deleteResumeMutation({ variables: { id } });
    navigate('/my-cvs');
  };
  const handleCreateRecommendation = async (inputData: RecommendationInput) =>
    createRecommendationMutation({
      variables: {
        resumeId: id,
        ...inputData,
      },
    });
  const handleSetPublished = async () => {
    const { data } = await setPublished({
      variables: { id, published: !isPublished },
    });
    setResumePublished(data.updateResume.published);
  };
  const handleSelectProfile = async (
    e: ChangeEvent<HTMLSelectElement>,
    id: string,
  ) => {
    const { value: profileId } = e.target;
    if (profileId) {
      await setProfile({ variables: { id, profileId } });
    }
  };
  const handleSelectLanguage = async (
    e: ChangeEvent<HTMLSelectElement>,
    id: string,
  ) => {
    const language = e.target.value as ECVLanguage;
    setSelectedLanguage(language);
  };
  // useQuery(PROFILES, {
  //   onCompleted: (data) => {
  //     setProfiles(data.profiles);
  //   },
  //   fetchPolicy: 'no-cache',
  // });
  const handleSavePdf = async () => {
    const res = await transformToPdf({ variables: { id } });
    await saveAs(
      'data:application/pdf;base64,' + res.data.transformToPdf,
      `${initialData?.title || 'resume'}.pdf`,
    );
  };
  const [atsTest] = useMutation(ATS_TEST, {
    onCompleted: (data) => {
      const { isPassed, content } = data.atsTest;
      showToast({
        content: content,
        duration: 10000,
        status: !isPassed ? 'warning' : 'success',
        update: true,
        isClosable: true,
      });
    },
    onError,
  });
  const [transformToHtml, { loading: transformToHtmlLoading }] = useMutation(
    TRANSFORM_TO_HTML,
    {
      onCompleted: (data) => {
        setPreview(data.transformToHtml);
        closeToast();
      },
      onError,
    },
  );

  const [updateLanguage, { loading: updateLanguageLoading }] = useMutation(
    UPDATE_RESUME_LANGUAGE,
    {
      onCompleted: async (data) => {
        setInitialData((initData) => ({
          ...initData,
          language: data.updateResumeLanguage.language,
        }));
        // await refetch().then(({ data }) => {
        //   const latestVersion = getLatestVersion(data?.findOneResume?.versions);
        //   setCurrentVersion(latestVersion);
        //   setResumeVersions(data?.findOneResume?.versions);
        // });
        showToast({
          content: t('notifications.translate.success'),
          status: 'success',
          update: true,
        });
      },
      onError,
    },
  );

  const handleUpdateCVLanguage = async (id: string, language: ECVLanguage) => {
    setIsPreviewing(false);
    setIsRewriting(true);
    setCurrentVersion((prev) =>
      prev
        ? {
            ...prev,
            content: t('notifications.generate'),
          }
        : prev,
    );
    try {
      showToast({
        content: t('notifications.translate.loading'),
        status: 'loading',
        duration: null,
      });
      await updateLanguage({ variables: { id, language } });
    } catch (error) {
      closeToast();
      console.log('ERROR Translate resume', error);
      setIsRewriting(false);
    }
  };

  const handlePreview = useCallback(async () => {
    if (!isPreviewing) {
      showToast({ content: 'Waiting for a CV preview.', status: 'loading' });

      await transformToHtml({
        variables: { id, versionId: currentVersion?.id },
      });
    }
    setIsPreviewing(!isPreviewing);
  }, [currentVersion?.id, id, isPreviewing, showToast, transformToHtml]);

  const improveCVHandler = async () => {
    setIsPreviewing(false);
    setIsRewriting(true);
    setCurrentVersion((prev) =>
      prev
        ? {
            ...prev,
            content: t('notifications.generate'),
          }
        : prev,
    );
    try {
      showToast({
        content: t('notifications.improve.loading'),
        status: 'loading',
        duration: null,
      });
      const result = await improveResume({
        variables: {
          id,
          versionId: currentVersion?.id,
          content: currentVersion?.content,
        },
      });
      if (result.data?.improveResume.content && id) {
        setContent(result.data.improveResume.content);
        setInitialData((data) => ({
          ...data,
          language: result.data.improveResume.language,
        }));
        showToast({
          content: t('notifications.improve.success'),
          status: 'success',
          update: true,
        });
      }
      setIsRewriting(false);
    } catch (error) {
      closeToast();
      console.log('ERROR Improve resume', error);
      setIsRewriting(false);
    }
    setIsRewriting(false);
  };

  const handleATSTest = () => {
    showToast({
      duration: null,
      content: 'Waiting for an ATS Test results',
      status: 'loading',
    });
    atsTest({ variables: { id } });
  };

  const handleRemoveVersion = (id: string) => {
    deleteVersionMutation({ variables: { id } });
  };

  useEffect(() => {
    const resume = resumes.find((resume) => resume.id === id);
    console.log('RESUME', resume);
    console.log('RESUMEs', resumes);
    if (!resume) {
      navigate('/404');
    } else {
      try {
        const {
          language,
          content,
          title,
          industry,
          company,
          published,
          job,
          description,
          versions,
          profile,
        } = resume as IResume;
        setContent(content ?? '');
        setSelectedLanguage(language);
        setInitialData({
          language,
          title: title ?? id ?? '',
          industry: industry ?? '',
          company: company ?? '',
          published,
          job: job ?? '',
          description: description ?? '',
          profileId: profile?.id,
        });
        const latestVersion = getLatestVersion(versions);
        setCurrentVersion(() => latestVersion);
        setResumeVersions(versions);
      } catch (error) {
        console.log('eRROR', error);
      }
    }
    return () => {
      setInitialData(defaultInitialData);
      setContent('');
      setPreview('');
    };
  }, [id, navigate, resumes, setContent, setInitialData, setPreview]);

  useEffect(() => {
    setResumePublished(initialData.published);
  }, [initialData.published, setResumePublished]);

  // TODO Fix update resume when exit from the page
  // useEffect(
  //   () => () => {
  //     if (
  //       editorRef.current &&
  //       editorRef.current.getValue() !== currentVersion?.content
  //     ) {
  //       updateResumeHandler(editorRef.current.getValue());
  //     }
  //   },
  //   [currentVersion?.content, updateResumeHandler],
  // );

  return {
    profiles,
    handleDeleteResume,
    handleSetPublished,
    handleSelectProfile,
    handleSavePdf,
    isPreviewing,
    setIsPreviewing,
    handlePreview,
    initialData,
    setInitialData,
    content,
    setContent,
    preview,
    setPreview,
    currentVersion,
    setCurrentVersion,
    isPublished,
    setResumePublished,
    improveCVHandler,
    atsTest,
    handleATSTest,
    handleCreateRecommendation,
    resumeVersions,
    setResumeVersions,
    transformToHtmlLoading,
    debouncedUpdate,
    handleUpdateCVData,
    handleVersionSelect,
    error,
    handleRemoveVersion,
    updateResumeHandler,
    handleSelectLanguage,
    selectedLanguage,
    handleUpdateCVLanguage,
    updateLanguageLoading,
    handleEditorDidMount,
  };
};
