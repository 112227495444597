import { StateCreator } from 'zustand';
import { TRecommendationsSlice } from './types';
import { fetchRecommendations } from './api';

export const createRecommendationsSlice: StateCreator<TRecommendationsSlice> = (
  set,
) => ({
  initRecommendations: async () => {
    const recommendations = await fetchRecommendations();
    if (recommendations) {
      set({ recommendations });
    }
  },
  recommendations: [],
  setRecommendations: (value) => set({ recommendations: value }),
});
