import { client } from 'src/graphql/client';
import { FIND_ALL_RECOMMENDATIONS } from 'src/graphql/queries';
import { IRecommendation, TPaginated } from 'src/types/types';

export const fetchRecommendations = async (): Promise<
  IRecommendation[] | void
> => {
  const request = client.query<{
    findAllRecommendations: TPaginated<IRecommendation>;
  }>({
    query: FIND_ALL_RECOMMENDATIONS,
    variables: {
      query: '',
    },
    // fetchPolicy: 'no-cache',
  });
  return request
    .then(({ data, errors }) => {
      if (data) {
        console.log('RECOMMENDATIoNS DATA', data);
        return data.findAllRecommendations.edges.map(({ node }) => node);
      }
      if (errors) {
        console.log('ERRORS', errors);
      }
      return [];
    })
    .catch((error) => {
      console.log('ERROR', error);
    });
};
