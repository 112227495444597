import { StateCreator } from 'zustand';
import { TResumesSlice, TUpdateResumeInput, TCreateResumeInput } from './types';
import {
  fetchCreateResume,
  fetchDeleteResume,
  fetchResumes,
  fetchUpdateResume,
} from './api';
import { getLoadingToast, getToast } from '../toasts';

export const createResumesSlice: StateCreator<TResumesSlice> = (set, get) => ({
  initResumes: async (noCache?: boolean) => {
    const resumes = await fetchResumes(noCache);
    if (resumes) {
      set({ resumes });
    }
  },
  resumes: [],
  setResumes: (value) => set({ resumes: value }),
  createResume: async (data: TCreateResumeInput) => {
    const toastId = getLoadingToast({
      title: 'Create CV.',
      description: 'CV creation in progress.',
      status: 'loading',
      duration: null,
    });
    const createdResume = await fetchCreateResume(data);
    await get().initResumes(true);
    if (createdResume) {
      set((state) => ({
        resumes: [{ ...createdResume }, ...state.resumes],
      }));
      getToast(
        {
          title: 'CV created.',
          description: `CV ${createdResume?.title} successfully created.`,
        },
        toastId,
      );

      return createdResume;
    }
    return;
  },
  updateResume: async (data: TUpdateResumeInput) => {
    const { id } = data;
    const updateResume = await fetchUpdateResume(data);
    if (updateResume) {
      set((state) => ({
        resumes: state.resumes.map((resume) =>
          resume.id === id ? { ...resume, ...updateResume } : resume,
        ),
      }));

      return updateResume;
    }
    return;
  },
  deleteResume: async (id) => {
    const isDeleted = await fetchDeleteResume(id);
    if (isDeleted) {
      let resumeTitle;
      set((state) => ({
        resumes: state.resumes.filter((cv) => {
          if (cv.id === id) {
            resumeTitle = cv.title;
          }
          return cv.id !== id;
        }),
      }));
      getToast({
        title: 'Delete success.',
        description: `Resume${
          resumeTitle ? `${resumeTitle} ` : ''
        } successfully deleted.`,
      });
    }
  },
});
