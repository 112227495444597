import React from 'react';

import 'driver.js/dist/driver.css';
import {
  Stack,
  Flex,
  SimpleGrid,
  Heading,
  Text,
  Button,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import AppModal from 'src/components/AppModal/AppModal';
import { useProfiles } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import CreateProfileModal from './CreateProfileModal';

const ProfilesPage = () => {
  const { t } = useTranslation('profiles');
  const { t: commonT } = useTranslation('common');
  const {
    profiles,
    onCreateProfileClick,
    onDeleteProfileClick,
    onEditProfileClick,
    selectedProfile,
    handleChange,
    isOpen,
    onClose,
    handleDeleteProfile,
    handleSubmit,
    deleteModalCloseHandler,
    deleteProfileHandler,
    isDeleteModalOpen,
  } = useProfiles();

  return (
    <Stack gap={2}>
      <Flex>
        <Heading as="h1" size="lg">
          {t('title')}
        </Heading>
        <Button
          id="create-profile-button"
          colorScheme="blue"
          onClick={onCreateProfileClick}
          ml="auto"
        >
          {t('create')}
        </Button>
      </Flex>
      <SimpleGrid
        spacing={4}
        templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      >
        {profiles.map(({ id, name, content }) => (
          <Flex
            key={id}
            flexDir="column"
            gap={2}
            p={4}
            borderRadius={6}
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.200"
          >
            <Heading size="sm">{name}</Heading>
            <Text size="xs" noOfLines={4}>
              {content}
            </Text>
            <ButtonGroup justifyContent="end" mt="auto">
              <IconButton
                aria-label="delete-profile"
                colorScheme="red"
                size="sm"
                icon={<DeleteIcon />}
                onClick={() => onDeleteProfileClick(id)}
              />
              <Button
                onClick={() => onEditProfileClick(id)}
                size="sm"
                ml="auto"
                mt="auto"
              >
                {t('edit')}
              </Button>
            </ButtonGroup>
          </Flex>
        ))}
      </SimpleGrid>
      <CreateProfileModal
        profile={selectedProfile}
        handleChange={handleChange}
        isOpen={isOpen}
        onClose={onClose}
        handleDeleteProfile={handleDeleteProfile}
        handleSubmit={handleSubmit}
      />
      <AppModal
        isOpen={isDeleteModalOpen}
        onClose={deleteModalCloseHandler}
        title={t('modal.sure')}
        actions={
          <ButtonGroup justifyContent="end">
            <Button onClick={deleteModalCloseHandler}>
              {commonT('cancel')}
            </Button>
            <Button colorScheme="red" onClick={deleteProfileHandler}>
              {commonT('confirm')}
            </Button>
          </ButtonGroup>
        }
        children={<div />}
      />
    </Stack>
  );
};

export default ProfilesPage;
