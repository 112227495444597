import { client } from 'src/graphql/client';
import { PROFILES } from 'src/graphql/queries';
import { TProfile } from 'src/types/types';
import { onErrorToastHandler, onErrorsToastHandler } from '../toasts';
import {
  CREATE_PROFILE,
  DELETE_PROFILE,
  UPDATE_PROFILE,
} from 'src/graphql/mutations';

export const fetchProfiles = async (): Promise<TProfile[] | void> => {
  const request = client.query<{
    profiles: TProfile[];
  }>({
    query: PROFILES,
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return;
      }
      if (data) {
        return data.profiles;
      }
      return;
    })
    .catch(onErrorToastHandler);
};

export const fetchDeleteProfile = async (
  id: string,
): Promise<void | TProfile | undefined> => {
  const request = client.mutate<{
    removeProfile: TProfile;
  }>({
    mutation: DELETE_PROFILE,
    variables: {
      id,
    },
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return;
      }
      if (data) {
        return data.removeProfile;
      }
      return;
    })
    .catch(onErrorToastHandler);
};

export const fetchCreateProfile = async (
  name: string,
  content: string,
): Promise<TProfile | void> => {
  const request = client.mutate<{
    createProfile: TProfile;
  }>({
    mutation: CREATE_PROFILE,
    variables: {
      name,
      content,
    },
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return;
      }
      if (data) {
        return data.createProfile;
      }
      return;
    })
    .catch(onErrorToastHandler);
};

export const fetchUpdateProfile = async (
  id: string,
  name: string,
  content: string,
): Promise<TProfile | void> => {
  const request = client.mutate<{
    updateProfile: TProfile;
  }>({
    mutation: UPDATE_PROFILE,
    variables: {
      id,
      name,
      content,
    },
  });
  return request
    .then(({ data, errors }) => {
      if (errors) {
        onErrorsToastHandler(errors);
        return;
      }
      if (data) {
        return data.updateProfile;
      }
      return;
    })
    .catch(onErrorToastHandler);
};
